<template>
  <div class="m_search_filter_wrap">
    <div class="m_search_item_wrap">
      <div class="m_filter_category_box">
        <el-select id="category"
                   popper-class="category_select judge_modal"
                   v-model="searchInputOption"
                   :popper-append-to-body="false"
                   class="m_my_filter select">
            <el-option :value="'na'" :label="$t('i_name')"></el-option>
            <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
            <el-option :value="'ni'" :label="$t('k_creator')"></el-option>
        </el-select>
      </div>
      <div class="m_search_input">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @keypress.enter.native="setFilterFunc(searchInputOption,searchInput)"/>
          <button class="m_search_btn" @click="setFilterFunc(searchInputOption,searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
<!--      <div class="m_end_filter">-->
<!--        <button class="m_filter_btn two" @click="openModal('filter-modal')"></button>-->
<!--      </div>-->
      <div class="m_filter_sort_box">
        <el-select id="sort_select" v-model="sortOption" class="m_sort_filter select"
                   popper-class="category_select judge_modal"
                   :popper-append-to-body="false"
                   @change="setSortFunc(sortOption)">
            <el-option :value="'ci_vote_cnt'" :label="$t('voteDesc')"></el-option>
            <el-option :value="'created_at'" :label="$t('b_update')"></el-option>
            <el-option :value="'score'" :label="$t('scoreDesc')"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import competitionUtil from "@/components/competition/competitionUtil";
import util from "@/mixins/util";
const {filterConfig} = competitionUtil
export default {
  name: "CompetitionFilterEntryModalLayout",
  mixins: [],
  components: {
  },
    inject: ['setFilter','setSort'],
  provide() {
    return{

    }
  },
  props: {
      filterData: {
          default: () => {
          }
      },
      sortData: {default: () => {}},
  },
  data() {
    return{
        searchInputOption: 'na',
        searchInput: '',
        sortOption: 'created_at',
        filterStatus:[],
        filterCategory:[],
        filterPrice:[],
        minPrice:'',
        maxPrice:''
    }
  },
  beforeRouterEnter() {},
  created() {
    this.setterFilterDataInData();
  },
  mounted() {
      this.setterFilterDataInData();
      if(!util.isEmpty(this.sortData)){
          this.setterSortDataInData()
      }
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
      setterFilterDataInData() {
          let classnames = Object.keys(this.filterData);
          classnames.forEach(classname => {
              let obj = this.filterData[classname];
              let type = filterConfig[obj.key].type;
              if (this.hasOwnProperty(classname)) {
                  if (type == 'query') {
                      this[classname] = obj.query;
                      if (this.hasOwnProperty(classname + 'Option')) {
                          this[classname + 'Option'] = obj.key;
                      }
                  } else if (type === 'between') {
                      this[classname] = [obj.start, obj.end];
                  } else if (type === 'match') {
                      if (isNaN(obj.value)) {
                          this[classname] = obj.value;
                      } else {
                          this[classname] = Number(obj.value);
                      }

                  } else if (type === 'terms') {
                      this[classname] = obj.terms;
                  }
              }
          })
      },
      setterSortDataInData(){
          if(this.sortData.order === 'asc'){
              this.sortOption = `${this.sortData.key}_asc`
          }else {
              this.sortOption = this.sortData.key
          }
      },
      setSortFunc(key) {
          let order = 'desc'
          if(key.includes('_asc')){
              let idx = key.indexOf('_asc')
              key = key.substring(0,idx)
              order = 'asc'
          }
          this.setSort(key,order)
      },
      setFilterFunc(key, value) {
          this.setFilter(key, value);
      },
    setData(key, value) {
      this[key] = value;
    },
    openModal(name) {
      this.$modal.show(name);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
